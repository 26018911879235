import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { startingAnOnlineStore } from "../../../data/landing/gated-v2";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import { lineOfBusiness, zipValidationRegExp } from "../../../constants";

const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);
const Image = loadable(() =>
  import("../../../components/ImageQuerys/LargeFeaturesImages")
);
const GatedCTA = loadable(() =>
  import("../../../components/CustomGated/GatedCTA")
);
const GatedSpecial = loadable(() =>
  import("../../../components/CustomGated/GatedSpecial")
);
const GatedSellingPoint = loadable(() =>
  import("../../../components/CustomGated/GatedSellingPoint")
);

const formDataToSend = ["name", "email", "phone", "company", "get_demo"];
const shortFormDataToSend = ["name", "email", "phone", "company", "get_demo"];
const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Retail point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetv2 = () => {
  return (
    <LpLayout phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="SpotOn Gated Assets | 7 Step Guide to Starting an Online Store"
        description="Download your free step-by-step guide and start selling your goods online with an omnichannel retail solution that does all the heavy lifting to help you increase revenue."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row className="gate-heading-v1" gutter={[32, 32]}>
        <Col
          lg={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <Heading
              injectionType={2}
              level={1}
              symbol="."
              className="text-white"
              withBlueSymbol
            >
              7 Step Guide to Starting an Online Store
            </Heading>
            <p className="gated-intro-para cta-light">
              An online store can broaden your reach and give you a powerful new
              revenue channel. Getting started can seem like a lot of work, but
              it’s easier than you think. Take your business online with
              intuitive tools for selling, shipping, and email marketing.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="gated-form-cont-v3">
            <GatedForm
              pageTitle="Title of the downloadable content"
              pageSubtext={subtext}
              formTitle="Download your free playbook"
              formItems={data}
              formId="4eb8938a-5deb-4106-80ab-7713c5c34a19"
              formDataToSend={formDataToSend}
              shortFormDataToSend={shortFormDataToSend}
              featureTitle="What you get"
              featureSubtext="Fill out the form to get your free copy today."
              blockList={blockList}
              topImg="asset-download.png"
              thankYouPath="/gated-assets/7-step-guide-to-starting-an-online-store/thank-you"
              gatedCampaign="cyber-monday"
              lineOfBusiness={lineOfBusiness.RETAIL_AND_SERVICES}
            />
          </div>

          <div className="form-bg">
            <Image imageName="Form-Bg.png" />
          </div>
        </Col>
        <Col lg={24}>
          <Image
            className="banner-text"
            imageName="retail-management-success-banner-text.png"
          />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={24} className="w-full">
          <GatedSpecial sectionData={startingAnOnlineStore} />
        </Col>

        <Col lg={24}>
          <GatedSellingPoint
            mainPoint="See how an omnichannel POS can help you launch, grow, or scale your business"
            mark="."
            subtext="With the right tech partner that is just as committed to your business’ success as you are, you can access all the tools you need to create a seamless online retail experience for you and your customers."
          />
        </Col>
      </Row>

      <Row
        gutter={[32, 32]}
        className="mt-10 lg:mt-20 mb-20 lg:mb-32 px-4 lg:px-0"
      >
        <Col xs={24}>
          <Image imageName="starting-an-online-store-b.png" />
        </Col>
      </Row>
    </LpLayout>
  );
};

export default GatedAssetv2;
